<template>
    <section class="hero verde-puwic is-fullheight">
        <div class="hero-body">
            <div class="container has-text-centered">
                <p class="title has-text-white">
                    Error 404 | Página no encontrada
                </p>
                <button class="button has-background-success-dark" @click="volver">
                    <span class="has-text-white is-size-4">volver</span>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    methods:{
        volver(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>


</style>